
import { defineComponent } from "vue";
import { ElMessageBox, ElMessage } from "element-plus";
import _ from "lodash";
export default defineComponent({
  name: "MyTeam",
  props: {
    teamId: String,
  },
  data() {
    return {
      inviteEmail: "",
      inviteEmailErrorMsg: "",
      validateInviteEmail: true,
      roleOptions: [] as any,
      inviteMemberModalStatus: false,
      form: {
        email: "",
      },
      errors: {
        email: false,
      },
      editTeamModalStatus: false,
      teamForm: {
        name: "",
      },
      teamErrors: {
        name: false,
      },
    };
  },
  computed: {
    team() {
      return this.$store.getters["team/team"];
    },
    members(): any[] {
      return this.$store.getters["team/members"];
    },
    user(): any {
      return this.$store.getters["user/user"];
    },
    personalTeam(): any {
      return this.$store.getters["team/personalTeam"];
    },
    isPersonalTeam(): boolean {
      if (this.$store.getters["team/personalTeam"]) {
        return this.$store.getters["team/personalTeam"]._id === this.teamId;
      }
      return false;
    },
  },
  watch: {
    inviteEmail: function (val) {
      this.checkingEmailStatus(val);
    },
    teamId: function (val) {
      if (val) {
        this.getList();
      }
    },
  },
  async mounted() {
    const user = this.$store.getters["user/user"];
    await this.$store.dispatch("team/getPersonalTeam", user.email, {
      root: true,
    });

    if (this.teamId) {
      this.getList();
    }

    this.roleOptions = [
      {
        value: "admin",
        label: (this as any).$i18n.t("myAccount.role_admin"),
        selected: false,
      },
      {
        value: "member",
        label: (this as any).$i18n.t("myAccount.role_member"),
        selected: false,
      },
    ];
  },
  methods: {
    lang(obj: any, attr: string) {
      const lang = (this as any).$i18n.getLocale(0) || "en";
      let label = "";
      if (_.isObject(obj)) {
        if (lang != "en") {
          attr = attr + "_" + lang;
        }
        if (Object.prototype.hasOwnProperty.call(obj, attr)) {
          label = (obj as any)[attr];
        }
      }
      return label;
    },
    checkingEmailStatus(email: string) {
      const emailValid = email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (email.length > 5 && emailValid) {
        this.errors.email = false;
        // this.inviteEmailErrorMsg = "";
      } else if (this.inviteEmail.length > 5 && !emailValid) {
        this.errors.email = true;
        // this.inviteEmailErrorMsg = "This is not a valid email";
      } else {
        this.errors.email = true;
        // this.inviteEmailErrorMsg = "";
      }
    },
    validate(): boolean {
      this.errors.email = false;
      this.checkingEmailStatus(this.form.email);
      if (this.errors.email) {
        return false;
      }

      // check existing email
      // console.log(_.find(this.members, { email: this.form.email }), "find");
      if (_.find(this.members, { email: this.form.email })) {
        ElMessage({
          message: (this as any).$t(
            "popup.alert.cannot_add_duplicated_member.message"
          ),
          type: "warning",
        });
        return false;
      }

      return true;
    },
    async getList(): Promise<any> {
      await this.$store.dispatch(
        "team/getMembers",
        { teamId: this.teamId },
        {
          root: true,
        }
      );
    },
    async sendInvite(email: string) {
      console.log("sendInvite()");
      // const email = this.inviteEmail;
      this.checkingEmailStatus(email);
      if (!this.validateInviteEmail) {
        return;
      }

      console.log(email, "email");

      /*
      await this.$store.dispatch(
        "referral/invite",
        { email: this.inviteEmail },
        { root: true }
      );
      if (this.$store.getters["referral/error"]) {
        this.validateInviteEmail = false;
        this.inviteEmailErrorMsg = this.$store.getters["referral/error"];
        return;
      }
      this.inviteEmail = "";
      ElMessage({
        message: (this as any).$t(
          "myAccount.refer_a_friend.refer_a_friend_success",
          { email: email }
        ),
        type: "success",
      });
      //*/
    },
    openInviteMemberModal() {
      if (!this.isAdmin(this.user.email)) return false;
      this.inviteMemberModalStatus = true;
    },
    closeInviteMemberModal() {
      this.inviteMemberModalStatus = false;
    },
    async addMember() {
      if (!this.validate()) return;

      if (!this.isAdmin(this.user.email)) return false;

      await this.$store.dispatch(
        "team/addMember",
        { teamId: this.teamId, email: this.form.email },
        {
          root: true,
        }
      );

      this.form.email = "";
      this.closeInviteMemberModal();

      this.getList();

      ElMessage({
        type: "success",
        message: (this as any).$t("popup.question.add_member.success_msg"),
      });
    },
    async changeRole({
      email,
      role,
    }: {
      email: string;
      role: string;
    }): Promise<any> {
      const admins = _.filter(this.members, { role: "admin" });
      if (admins.length == 0 && role != "admin") {
        ElMessageBox.alert(
          (this as any).$t("popup.alert.cannot_remove_last_admin.message"),
          (this as any).$t("popup.alert.cannot_remove_last_admin.title"),
          {
            showClose: false,
            confirmButtonText: (this as any).$t("popup.optional.Got_it"),
            callback: () => {
              const member = _.find(this.members, { email: email });
              member.role = "admin";
              this.changeRole({ email: email, role: "admin" });
            },
          }
        );
        return;
      }

      if (this.user.email == email && role != "admin") {
        ElMessageBox.confirm(
          (this as any).$t("popup.question.cannot_be_admin.question"),
          (this as any).$t("popup.question.cannot_be_admin.title"),
          {
            confirmButtonText: (this as any).$t("popup.optional.Confirm"),
            cancelButtonText: (this as any).$t("popup.optional.Cancel"),
            type: "warning",
          }
        )
          .then(async () => {
            this.$store.dispatch(
              "team/changeRole",
              { teamId: this.teamId, email: email, role: role },
              {
                root: true,
              }
            );
          })
          .catch((error) => {
            const member = _.find(this.members, { email: email });
            member.role = "admin";
            this.changeRole({ email: email, role: "admin" });

            if (error == "cancel") return;
            console.error(error);
          });
        return;
      }

      this.$store.dispatch(
        "team/changeRole",
        { teamId: this.teamId, email: email, role: role },
        {
          root: true,
        }
      );

      ElMessage({
        type: "success",
        message: (this as any).$t("popup.question.change_role.success_msg"),
      });
    },
    isAdmin(email: string): boolean {
      const member = _.find(this.members, { email: email, role: "admin" });
      return _.isObject(member);
    },
    isMember(email: string): boolean {
      const member = _.find(this.members, { email: email });
      return _.isObject(member);
    },
    // no longer used
    canInvite(email: string): boolean {
      if (this.isAdmin(email)) {
        return false;
      }
      return false;
    },
    allowToLeave(email: string): boolean {
      if (this.isAdmin(email)) {
        const admins = _.filter(this.members, { role: "admin" });
        for (const i in admins) {
          if (admins[i].email != this.user.email) {
            return true;
          }
        }
        return false;
      }
      return true;
    },
    canLeave(email: string): boolean {
      const member = _.find(this.members, { email: email });
      if (member && member.email == this.user.email) {
        return true;
      }
      return false;
    },
    canRemove(email: string): boolean {
      if (!this.isAdmin(this.user.email)) {
        return false;
      }
      const member = _.find(this.members, { email: email });
      if (member && member.email != this.user.email) {
        return true;
      }
      return false;
    },
    async doRemoveMember(
      teamId: string | undefined,
      email: string
    ): Promise<any> {
      await this.$store.dispatch(
        "team/removeMember",
        { teamId: teamId, email: email },
        {
          root: true,
        }
      );

      if (this.$store.getters["team/error"]) {
        console.log(this.$store.getters["team/error"], "error");
        return;
      }
    },
    async doLeaveTeam(email: string): Promise<any> {
      if (!this.canLeave(email)) return false;
      if (!this.allowToLeave(email)) return false;

      ElMessageBox.confirm(
        (this as any).$t("popup.question.leave_team.question"),
        (this as any).$t("popup.question.leave_team.title"),
        {
          confirmButtonText: (this as any).$t("popup.optional.Yes_chi"),
          cancelButtonText: (this as any).$t("popup.optional.Cancel"),
          type: "warning",
        }
      )
        .then(async () => {
          await this.doRemoveMember(this.teamId, email);
          ElMessage({
            type: "success",
            message: (this as any).$t("popup.question.leave_team.success_msg"),
          });
          setTimeout(() => {
            this.$router.push({
              name: "MyAccountWithTab",
              params: { tab: "my-profile" },
            });
          }, 2000);
        })
        .catch((error) => {
          if (error == "cancel") return;
          console.error(error);
        });
    },
    async removeMember(email: string): Promise<any> {
      ElMessageBox.confirm(
        (this as any).$t("popup.question.remove_member.question"),
        (this as any).$t("popup.question.remove_member.title"),
        {
          confirmButtonText: (this as any).$t("popup.optional.Yes_chi"),
          cancelButtonText: (this as any).$t("popup.optional.Cancel"),
          type: "warning",
        }
      )
        .then(async () => {
          await this.doRemoveMember(this.teamId, email);
          this.getList();
          ElMessage({
            type: "success",
            message: (this as any).$t(
              "popup.question.remove_member.success_msg"
            ),
          });
        })
        .catch((error) => {
          if (error == "cancel") return;
          console.error(error);
        });
    },
    validateTeam(): boolean {
      this.teamErrors.name = false;
      if (!this.teamForm.name) {
        this.teamErrors.name = true;
        return false;
      }

      // const specialChars = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;
      const specialChars = /[`!@#$%^&*()+\-=\\[\]{};':"\\|,<>\\/?~]/;
      this.teamErrors.name = specialChars.test(this.teamForm.name);
      if (this.teamErrors.name) {
        return false;
      }

      return true;
    },
    async doEditTeam(): Promise<any> {
      // console.log("doEditTeam()");
      if (!this.validateTeam()) return;

      const formDetails = {
        _id: this.teamId,
        name: this.teamForm.name,
      };

      this.editTeamModalStatus = false;

      await this.$store.dispatch("team/saveTeam", formDetails, {
        root: true,
      });

      // Update dropdown list of teams
      this.$store.dispatch("team/getTeams");

      this.$store.dispatch(
        "team/getTeam",
        { teamId: this.teamId },
        { root: true }
      );

      ElMessage({
        message: (this as any).$t("popup.question.save_team.success_msg"),
        type: "success",
      });
    },
    async editTeam(): Promise<any> {
      this.teamForm.name = this.team.name;
      this.editTeamModalStatus = true;
    },
    async deleteTeam(): Promise<any> {
      ElMessageBox.confirm(
        (this as any).$t("popup.question.delete_team.question"),
        (this as any).$t("popup.question.delete_team.title", {
          teamName: this.team.name,
        }),
        {
          confirmButtonText: (this as any).$t("popup.optional.Confirm_Delete"),
          cancelButtonText: (this as any).$t("popup.optional.Cancel"),
          type: "warning",
          customClass: "error-message-box",
          dangerouslyUseHTMLString: true,
        }
      )
        .then(async () => {
          console.log("doDeleteTeam()");

          ElMessage({
            message: (this as any).$t(
              "popup.question.delete_team_success.success_msg"
            ),
            type: "success",
          });

          await this.$store.dispatch(
            "team/deleteTeam",
            { teamId: this.teamId },
            {
              root: true,
            }
          );

          setTimeout(async () => {
            // Goto personal team
            await this.$store.dispatch(
              "user/changeTeam",
              this.personalTeam._id,
              {
                root: true,
              }
            );
            window.location.reload();
          }, 1500);
        })
        .catch((error) => {
          if (error == "cancel") return;
          console.error(error);
        });
    },
    closeTeamModal() {
      this.beforeCloseTeamModal();
      this.editTeamModalStatus = false;
    },
    beforeCloseTeamModal(shouldCance?: () => void) {
      if (shouldCance) shouldCance();
    },
  },
});
