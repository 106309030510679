
import { defineComponent } from "vue";
import { ElLoading } from "element-plus";
import _ from "lodash";
import ReferAFriend from "./MyAccount/ReferAFriend.vue";
import MyProfile from "./MyAccount/MyProfile.vue";
import MyCompany from "./MyAccount/MyCompany.vue";
import PlanAndBilling from "./MyAccount/PlanAndBilling.vue";
import MyTeam from "./MyAccount/MyTeam.vue";
import ApiKey from "./MyAccount/ApiKey.vue";
// import _ from "lodash";
// import helpers from "@/helpers/global";
// import {
//   getStorage,
//   ref,
//   uploadString,
//   getDownloadURL,
// } from "firebase/storage";

let loadingEl: any;

export default defineComponent({
  name: "MyAccount",
  components: {
    ReferAFriend,
    MyProfile,
    MyCompany,
    PlanAndBilling,
    MyTeam,
    ApiKey,
  },
  async created() {
    // console.log("MyAccount created");
    // @see src\views\MyAccount.vue
    // await this.$store.dispatch("user/loadCurrentUser", null, true);

    // Add Omise.js
    const script = document.createElement("script");
    script.setAttribute("src", "https://cdn.omise.co/omise.js");
    document.body.appendChild(script);

    // Get last page visited
    const lastPage = String(this.$router.options.history.state.back) || "";
    if (lastPage && !lastPage.includes("/account")) {
      this.lastPage = lastPage;
    } else {
      this.lastPage = "/";
    }
  },
  async mounted() {
    if (!this.$store.getters["user/user"]) {
      await this.$store.dispatch("user/loadCurrentUser");
    }
    const user = this.$store.getters["user/user"];
    this.color = user.color;

    await this.$store.dispatch("team/getTeams");

    let tab = this.$router.currentRoute.value.params.tab || false;
    if (!tab) {
      const teamId = this.$router.currentRoute.value.params.teamId;
      if (teamId) {
        tab = "my-team";
        this.activeTeamId = teamId;
        await this.$store.dispatch(
          "team/getMembers",
          { teamId: teamId },
          {
            root: true,
          }
        );
        if (this.$store.getters["team/error"]) {
          this.activeTeamId = "";
          setTimeout(() => {
            this.$router.push({
              name: "MyAccountWithTab",
              params: { tab: "my-profile" },
            });
          }, 2000);
        }
      } else {
        if (user.teamId) {
          this.activeTeamId = user.teamId;
          this.$store.commit("team/activeTeamId", user.teamId);
        } else {
          this.selectPersonalTeam();
        }
      }
    } else {
      // this.selectPersonalTeam();
      this.activeTeamId = user.teamId;
    }
    if (tab) {
      switch (tab) {
        case "my-profile":
          this.activeTab = "My_Profile";
          break;
        case "my-company":
          this.activeTab = "My_Company";
          break;
        case "plan-billing":
          this.activeTab = "Plan_Billing";
          break;
        case "refer-a-friend":
          this.activeTab = "Refer_a_friend";
          break;
        case "my-team":
          this.activeTab = "My_Team";
          break;
        case "api-key":
          this.activeTab = "API_Key";
          break;
      }
    }

    this.copyOptions = [
      {
        value: "company_and_branding",
        label: "Company & Branding Info",
        label_th: "ข้อมูลบริษัทและการสร้างแบรนด์",
        selected: false,
      },
      {
        value: "all_assessments_and_responses",
        label: "All Assessments & Responses",
        label_th: "การประเมินและการตอบกลับทั้งหมด",
        selected: false,
      },
    ];

    // test: create team
    /*
    const formDefault = {
      name: "Naruto",
      copyOptions: {
        all_assessments_and_responses: true,
        company_and_branding: true,
      },
    };
    await this.$store.dispatch("team/createTeam", formDefault, {
      root: true,
    });
    //*/
  },
  data() {
    return {
      lastPage: "",
      activeTab: "My_Profile",
      activeTeamId: "" as any,
      newTeamModalStatus: false,
      userDetail: {
        id: "",
        firstname: "",
        lastname: "",
        company_name: "",
        company_color: "#1B1C1E",
        logo: "",
        company_logo: "",
        imageFile: "",
      },
      color: "",
      form: {
        name: "",
      },
      errors: {
        name: false,
      },
      copyOptions: [] as any,
      createTeamLoading: false,
      createTeamDisabled: false,
    };
  },
  computed: {
    // yourPlan() {
    //   const plan = "Pay as you go";
    //   return plan;
    // },
    paymentEnabled() {
      return process.env.VUE_APP_PAYMENT_ENABLED === "on";
    },
    totalCredit() {
      return 11 + " credit remaining";
    },
    user(): any {
      return this.$store.getters["user/user"];
    },
    // quota() {
    //   return this.$store.getters["quota/data"];
    // },
    companyLogoUrl(): string {
      return this.$store.getters["user/companyLogoUrl"];
    },
    loading(): boolean {
      return this.$store.getters["payment/loading"];
    },
    tabParam(): any {
      return this.$router.currentRoute.value.params.tab;
    },
    paymentError(): any {
      return this.$store.getters["payment/error"];
    },
    couponError(): any {
      return this.$store.getters["coupon/error"];
    },
    couponLoading(): any {
      return this.$store.getters["coupon/loading"];
    },
    teams(): any[] {
      let teams = _.cloneDeep(this.$store.getters["team/teams"] || []).map(
        (team: any) => {
          return {
            label: team.name,
            value: team._id,
          };
        }
      );
      teams.push({
        label: (this as any).$i18n.t("myAccount.Create_a_new_team"),
        value: "new",
      });
      return teams;
    },
  },
  watch: {
    // quota(value: any) {
    //   if (value) {
    //     this.updateQuotaDisplay();
    //   }
    // },
    paymentError(value) {
      if (value) {
        console.log(value, "payment error");
      }
    },
    // @fix if url is changed from credit menu
    tabParam(value) {
      // console.log(value, "tabParam");
      if (!value) return;
      switch (value) {
        case "my-profile":
          this.activeTab = "My_Profile";
          break;
        case "my-company":
          this.activeTab = "My_Company";
          break;
        case "plan-billing":
          this.activeTab = "Plan_Billing";
          break;
        case "refer-a-friend":
          this.activeTab = "Refer_a_friend";
          break;
        case "my-team":
          this.activeTab = "My_Team";
          break;
        case "api-key":
          this.activeTab = "API_Key";
          break;
      }
    },
    activeTab(tab: string) {
      if (tab) {
        let paramsTab = "";
        switch (tab) {
          case "My_Profile":
            paramsTab = "my-profile";
            break;
          case "My_Company":
            paramsTab = "my-company";
            break;
          case "Plan_Billing":
            paramsTab = "plan-billing";
            break;
          case "Refer_a_friend":
            paramsTab = "refer-a-friend";
            break;
          case "My_Team":
            paramsTab = "my-team";
            break;
          case "API_Key":
            paramsTab = "api-key";
            break;
        }

        if (tab == "My_Team" && this.$router.currentRoute.value.params.teamId) {
          this.$router.push({
            name: "MyAccountWithTeamId",
            params: { teamId: this.$router.currentRoute.value.params.teamId },
          });
        } else {
          this.$router.push({
            name: "MyAccountWithTab",
            params: { tab: paramsTab },
          });

          // auto open `create team` modal
          if (tab == "My_Team") {
            const teams = this.$store.getters["team/teams"];
            let notFoundTeam = true;
            if (teams && teams.length) {
              notFoundTeam = _.filter(teams, { personal: false }).length < 1;
            }
            if (notFoundTeam) {
              this.changeTeam("new");
            }
          }
        }
      }
    },
    loading(value: any) {
      if (value) {
        loadingEl = ElLoading.service({
          lock: true,
          text: "Login..",
          background: "#ffffff90",
        });
      } else {
        loadingEl.close();
      }
    },
  },
  methods: {
    backLastPage() {
      let lastPage = String(this.lastPage ? this.lastPage : "/");
      if (lastPage) lastPage = "/";
      this.$router.push(lastPage);
    },
    test(value: string) {
      console.log("test:", value);
    },
    active(tab: string) {
      this.activeTab = tab;
    },
    activeClass(tab: string) {
      if (this.activeTab === tab) {
        return "active";
      }
      return "";
    },
    async changeTeam(value: any): Promise<void> {
      if (value == "new") {
        // create new team
        this.newTeamModalStatus = true;
      } else {
        await this.$store.dispatch("user/changeTeam", value, { root: true });

        // set active team
        // "/account/my-team/:teamId"

        // use redirect for resolve company color doesn't change when change team especially in my component scss
        // window.location.href = "/account/my-team";
        window.location.reload();

        // this.$router.push({
        //   name: "MyAccountWithTeamId",
        //   params: { teamId: value },
        // });
        // this.activeTab = "My_Team";
        // this.activeTeamId = value;
      }
    },
    validate(): boolean {
      this.errors.name = false;
      if (!this.form.name) {
        this.errors.name = true;
        return false;
      }

      // const specialChars = /[ `!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/;
      const specialChars = /[`!@#$%^&*()+\-=\\[\]{};':"\\|,<>\\/?~]/;
      this.errors.name = specialChars.test(this.form.name);
      if (this.errors.name) {
        return false;
      }

      return true;
    },
    async createTeam(): Promise<any> {
      if (this.createTeamDisabled && this.createTeamLoading) return;

      if (!this.validate()) return;

      this.createTeamDisabled = true;
      this.createTeamLoading = true;

      const formDetails = {
        name: this.form.name,
        copyOptions: {},
      } as any;
      _.each(this.copyOptions, (option: any) => {
        formDetails.copyOptions[option.value] = option.selected;
      });

      await this.$store.dispatch("team/createTeam", formDetails, {
        root: true,
      });
      const newTeamId = this.$store.getters["team/activeTeamId"];

      // reset form
      this.form.name = "";
      _.each(this.copyOptions, (option: any) => {
        option.selected = false;
      });

      this.createTeamDisabled = false;
      this.createTeamLoading = false;
      this.newTeamModalStatus = false;

      setTimeout(async () => {
        await this.$store.dispatch("team/getTeams");
        this.$router.push({
          name: "MyAccountWithTeamId",
          params: { teamId: newTeamId },
        });
        this.activeTab = "My_Team";
        this.activeTeamId = newTeamId;
        // this.changeTeam(newTeamId);
        await this.$store.dispatch("user/changeTeam", newTeamId, {
          root: true,
        });
        await this.$store.dispatch(
          "team/getMembers",
          { teamId: this.activeTeamId },
          {
            root: true,
          }
        );
      }, 2000);
    },
    async selectPersonalTeam(): Promise<any> {
      const user = this.$store.getters["user/user"];

      // Personal team is default team
      await this.$store.dispatch("team/getPersonalTeam", user.email, {
        root: true,
      });
      const personalTeam = this.$store.getters["team/personalTeam"];
      if (personalTeam) {
        this.activeTeamId = personalTeam._id;
        this.$store.commit("team/activeTeamId", personalTeam._id);
      }
    },
    closeTeamModal() {
      if (false !== this.beforeCloseTeamModal()) {
        this.newTeamModalStatus = false;
      }
    },
    beforeCloseTeamModal(shouldCancel?: () => void) {
      if (this.createTeamDisabled && this.createTeamLoading) {
        return false;
      }

      if (shouldCancel) shouldCancel();

      if (this.activeTeamId == "new") {
        this.selectPersonalTeam();
      }
    },
    copyOptionCls(value: string) {
      const option = _.find(this.copyOptions, { value: value });
      if (option) {
        return option.selected ? "selected" : "disable";
      }
      return "disable";
    },
    selectCopyOption(value: any): any {
      const option = _.find(this.copyOptions, { value: value });
      if (option) {
        option.selected = Boolean(option.selected);
        option.selected = !option.selected;
      }
    },
    lang(obj: any, attr: string) {
      const lang = (this as any).$i18n.getLocale(0) || "en";
      let label = "";
      if (_.isObject(obj)) {
        if (lang != "en") {
          attr = attr + "_" + lang;
        }
        if (Object.prototype.hasOwnProperty.call(obj, attr)) {
          label = (obj as any)[attr];
        }
      }
      return label;
    },
  },
});
