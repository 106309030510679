
import { defineComponent } from "vue";
import { ElMessage, ElMessageBox } from "element-plus";
import _ from "lodash";

export default defineComponent({
  name: "ApiKey",
  props: {
    teamId: String,
  },
  data() {
    return {
      canRegenerate: true,
      regenerateButtonLoading: false,
    };
  },
  computed: {
    apiKey() {
      return this.$store.getters["greenhouse/apiKey"];
    },
    team() {
      return this.$store.getters["team/team"];
    },
  },
  async mounted() {
    // auto generate api key for greenhouse
    const apiKey = _.get(this.team, "greenhouse.api_key");
    if (!apiKey) {
      await this._regenerate();
    } else {
      this.$store.commit("greenhouse/apiKey", apiKey, { root: true });
    }
  },
  methods: {
    async _regenerate() {
      console.log("_regenerate()");
      await this.$store.dispatch(
        "greenhouse/generate",
        { teamId: this.teamId },
        { root: true }
      );
    },
    regenerate() {
      ElMessageBox.confirm(
        (this as any).$t("myAccount.API_Key_generating_warning"),
        (this as any).$t("popup.question.question_box_2.title"),
        {
          confirmButtonText: (this as any).$t("popup.optional.Yes_chi"),
          cancelButtonText: (this as any).$t("popup.optional.Cancel"),
          type: "warning",
          customClass: "message-box-no-yes",
        }
      )
        .then(async () => {
          this.regenerateButtonLoading = true;
          await this._regenerate();
          this.regenerateButtonLoading = false;
        })
        .catch((error) => {
          if (error == "cancel") return;
          console.error(error);
        });
    },
    copyKey() {
      if (!this.canRegenerate) {
        // show error message can't invite
        ElMessage.error((this as any).$t("popup.message.message_4"));
        return false;
      }
      if (!this.apiKey) return;
      ElMessage({
        message: (this as any).$t("popup.message.message_8"),
        type: "success",
      });
      // console.log(this.apiKey, "this.apiKey");
      const clipboardData =
        (window as any).clipboardData || navigator.clipboard;
      clipboardData.writeText(this.apiKey);
    },
  },
});
