
import { defineComponent } from "vue";
import { ElMessage } from "element-plus";

export default defineComponent({
  name: "ReferAFriend",
  data() {
    return {
      // refferralLink: "myculture.happily.ai/join?clw-dkb-qed",
      inviteEmail: "",
      inviteEmailErrorMsg: "",
      validateInviteEmail: true,
      // referralsData: [
      //   {
      //     date: "10/2022",
      //     name: "James C",
      //     description: "Assessment Created",
      //     credits: "+20 credits",
      //   },
      //   {
      //     date: "10/2022",
      //     name: "John K",
      //     description: "Pending Assessment",
      //     credits: "+0 credits",
      //   },
      // ],
    };
  },
  props: {
    msg: String,
  },
  watch: {
    inviteEmail: function (val) {
      this.checkingEmailStatus(val);
    },
  },
  mounted() {
    const user = this.$store.getters["auth/user"] || {};
    this.$store.dispatch(
      "referral/getPublicLink",
      { email: user.email },
      {
        root: true,
      }
    );
    this.$store.dispatch(
      "referral/getReferrals",
      { email: user.email },
      {
        root: true,
      }
    );
  },
  computed: {
    refferralLink() {
      if (this.$store.getters["referral/publicLink"]) {
        return (
          window.location.origin +
          "/" +
          this.$store.getters["referral/publicLink"]
        );
      } else {
        return "";
      }
    },
    referrals() {
      return this.$store.getters["referral/referrals"];
    },
  },
  methods: {
    checkingEmailStatus(email: string) {
      const emailValid = email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
      if (this.inviteEmail.length > 5 && emailValid) {
        this.validateInviteEmail = true;
        // this.inviteEmailErrorMsg = "";
      } else if (this.inviteEmail.length > 5 && !emailValid) {
        this.validateInviteEmail = false;
        // this.inviteEmailErrorMsg = "This is not a valid email";
      } else {
        this.validateInviteEmail = false;
        // this.inviteEmailErrorMsg = "";
      }
    },
    async sendInvite() {
      const email = this.inviteEmail;
      if (!this.validateInviteEmail) {
        return;
      }
      await this.$store.dispatch(
        "referral/invite",
        { email: this.inviteEmail },
        { root: true }
      );
      if (this.$store.getters["referral/error"]) {
        this.validateInviteEmail = false;
        this.inviteEmailErrorMsg = this.$store.getters["referral/error"];
        return;
      }
      this.inviteEmail = "";
      ElMessage({
        message: (this as any).$t(
          "myAccount.refer_a_friend.refer_a_friend_success",
          { email: email }
        ),
        type: "success",
      });
    },
    copyLink() {
      const clipboardData =
        (window as any).clipboardData || navigator.clipboard;
      clipboardData.writeText(this.refferralLink);
      ElMessage({
        message: (this as any).$t("popup.message.message_17"),
        grouping: true,
        type: "success",
      });
    },
    referralDate(createdAt: string) {
      const parts = createdAt.split("-");
      return parts[1] + "/" + parts[0];
    },
  },
});
